.action-btn {
    /* margin-top: 20px; */
    width: 100%;
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    transition: 0.3s;
}

.action-btn.verify {
    background-color: #8ac926 !important;
    border: 1px solid #8ac926 !important;

}

.action-btn.archive {
    background-color: #ff595e !important;
    border: 1px solid #ff595e !important;
}

.action-btn.unsure {
    background-color: #ffca3a !important;
    border: 1px solid #ffca3a !important;
}

.action-btn.skip {
    background-color: #1982c4 !important;
    border: 1px solid #1982c4 !important;
}


.control-label {
    font-size: small;
    color: #495057;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 4px;
}

.form-label {
    font-size: smaller;
    color: #626970;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 4px;
}

.ant-divider.ant-divider-horizontal{
    margin: 4px 0px !important;
}